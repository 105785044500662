<template>
  <modalDefaultVue
    :modalId="`extrair`"
    :modalTitle="
      `Relatório de Despachante - Emitidos${
        get_tenant.isDispatcher ? '' : ' Pela Unidade'
      }`
    "
    :load="false"
    :typeLg="'modal_wd'"
    @closeModal="closeModal"
  >
    <ValidationObserver ref="refFilter" tag="div">
      <div class="col-12 d-flex p-0">
        <div
          class="col-md-3 mb-1 form-group form-outline d-flex flex-column p-0"
          name="Data Inicial"
        >
          <label class="mb-1 label_default">Data Inicial</label>
          <input type="date" v-model="payloadRelatorio.dateInit" />
        </div>
        <div
          class="col-md-3 mb-1 form-group form-outline d-flex flex-column px-2"
          name="Data Final"
        >
          <label class="mb-1 label_default">Data Final</label>
          <input type="date" v-model="payloadRelatorio.dateFinish" />
        </div>
        <div
          v-if="get_tenant.isDispatcher"
          class="col-md-6 mb-1 form-group form-outline d-flex flex-column p-0"
          name="Unidade"
        >
          <div class="multiselec col-12">
            <label class="mb-1 label_default">Unidade</label>
            <multiselect
              v-model="valueTenant"
              :options="tenantOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :show-labels="false"
              :searchable="false"
              :maxHeight="150"
              :limit="1"
              :limit-text="limitText"
              placeholder="Selecionar Unidades"
              label="name"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="valueTenant.length && !isOpen"
                  >{{
                    valueTenant.length > 1
                      ? `${valueTenant.length} selecionados`
                      : `${valueTenant.length} selecionado`
                  }}
                </span>
              </template>
              <span slot="noOptions">Lista vazia</span>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex p-0">
        <div
          class="form-group form-outline d-flex flex-column col-6 p-0"
          name="Serviços"
        >
          <label class="mb-1 label_default">Serviços</label>
          <select v-model="payloadRelatorio.service">
            <option value=""></option>
            <option :value="item.id" v-for="item in service">{{
              item.name
            }}</option>
          </select>
        </div>
        <div class="form-group form-outline d-flex flex-column ml-1 col-6 p-0">
          <label class="mb-1 label_default">Status e Tag</label>
          <multiselect
            v-model="valueStatus"
            :options="statusOptions"
            :multiple="true"
            :loading="loadingStatus"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :show-labels="false"
            :searchable="false"
            :maxHeight="150"
            :limit="1"
            :limit-text="limitText"
            group-values="items"
            group-label="groupName"
            placeholder="Selecionar Status"
            label="name"
            track-by="name"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="valueStatus.length && !isOpen"
                >{{
                  valueStatus.length > 1
                    ? `${valueStatus.length} selecionados`
                    : `${valueStatus.length} selecionado`
                }}
              </span>
            </template>
            <span slot="noOptions">Lista vazia</span>
          </multiselect>
        </div>
      </div>
    </ValidationObserver>
    <div class="d-flex justify-content-end mt-3">
      <button
        class="btn_default btn d-flex align-items-center justify-content-center"
        @click="sendExtrair"
      >
        <half-circle-spinner
          v-if="load"
          :animation-duration="1000"
          :size="30"
          color="#fff"
        />
        <div v-else>
          Extrair
        </div>
      </button>
    </div>

    <csvExportVue :dataJson="currentData" />
  </modalDefaultVue>
</template>

<script>
import modalDefaultVue from "../../modal/modalDefault.vue";
import csvExportVue from "./csvExport.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { HubConnectionBuilder } from "@aspnet/signalr";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["tenants", "service", "status", "tags"],
  components: {
    modalDefaultVue,
    csvExportVue,
    HalfCircleSpinner
  },
  data() {
    return {
      load: false,
      porcentValue: 0,
      valueTenant: [],
      valueStatus: [],
      statusOptions: [],
      loadingStatus: true,
      payloadRelatorio: {
        dateInit: "",
        dateFinish: "",
        tenants: [],
        service: "",
        tags: []
      },
      allHistory: [],
      currentData: []
    };
  },
  computed: {
    ...mapGetters(["getUser", "tenantRequire", "get_tenant"]),
    tenantOptions() {
      if (this.tenants.length) {
        return this.tenants.map(t => ({ id: t.id, name: t.name }));
      }
      return [];
    }
  },
  methods: {
    ...mapActions([
      "ExportDispatcher",
      "getExcel",
      "exportClient",
      "getStatusAndTags"
    ]),
    closeModal() {
      $("#extrair").modal("hide");
      this.payloadRelatorio = {};
      this.valueStatus = [];
      this.valueTenant = [];
    },
    sendExtrair() {
      const {
        dateInit,
        dateFinish,
        tenants,
        service,
        tags
      } = this.payloadRelatorio;
      let params = {
        tenantIds: tenants,
        orderId: "",
        vehiclePlate: "",
        serviceId: service,
        statusTags: tags,
        dateInitial: dateInit,
        dateFinale: dateFinish
      };
      this.request(params);
      this.closeModal();
    },
    request(params) {
      if (this.getUser.profile == 2 && !this.get_tenant.isDispatcher) {
        this.exportClient(params)
          .then(res => {
            this.toast("Aguarde...", this.$toast.info);
            this.exctrator();
            return;
          })
          .catch(err => {
            console.log(err);
            this.toast(err.response.data.error.details, this.$toast.error);
          });
        return;
      }

      this.ExportDispatcher(params)
        .then(res => {
          this.toast("Aguarde...", this.$toast.info);
          this.$emit("exctrator");
        })
        .catch(err => {
          console.log(err);
          this.toast(err.response.data.error.details, this.$toast.error);
        });
    },
    exctrator() {
      this.getExcel(
        this.getUser.profile == 2 && !this.get_tenant.isDispatcher
          ? this.getUser.profile + 2
          : this.getUser.profile + 1
      )
        .then(res => {
          const allResult = res.data.result;
          const result = res.data.result;
          this.$emit("update");
          return;
          result.forEach(item => {
            if (item.report.length > 0) {
              console.log(item.report);
              this.currentData = item;
            }
          });

          // this.currentData = result.report
          this.load = false;
        })
        .catch(err => {
          this.load = false;
          this.$emit("load", false);
          console.log(err);
        });
    },
    async getStatus() {
      try {
        const tenantId = this.tenantRequire.tenantId
          ? this.tenantRequire.tenantId
          : "";
        const { data } = await this.getStatusAndTags({ tenantId });
        const { result } = data;
        this.loadingStatus = false;
        if (result.length) {
          this.statusOptions = result;
        }
      } catch (error) {
        this.loadingStatus = false;
      }
    },
    limitText(count) {
      return `e mais ${count}`;
    }
  },
  mounted() {
    this.getStatus();
  },
  watch: {
    valueStatus() {
      this.payloadRelatorio.tags = [];
      this.payloadRelatorio.status = [];
      if (this.valueStatus.length) {
        let groupNames = [
          ...new Set(this.valueStatus.map(t => t.groupName))
        ].map(t => ({
          groupName: t,
          statusTags: this.valueStatus
            .filter(vf => vf.groupName === t)
            ?.map(x => x.id)
        }));
        this.payloadRelatorio.tags = groupNames;
      }
    },
    valueTenant() {
      this.payloadRelatorio.tenants = [];
      if (this.valueTenant.length) {
        this.payloadRelatorio.tenants = this.valueTenant.map(t => t.id);
      }
    }
  }
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-size: 16px;
  color: #797984;
}

input {
  background: #ffffff;
  border: 1px solid #98c4e6;
  border-radius: 6px;
  height: 45px;
  padding: 8px;
}

select {
  background: #ffffff;
  border: 1px solid #98c4e6;
  border-radius: 6px;
  height: 45px;
  padding: 8px;
}

.btn {
  height: 40px;
  width: 163px !important;
  border: none;
}
.multiselect {
  border: 1px solid #98c4e6;
  border-radius: 6px !important;
  height: 40px !important;
  box-shadow: none;
}
</style>
