<template>
  <div class="containerTag" :style='`display:${display}`'>
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="m-0">Etiquetas</h2>
      <button class="close" @click="$emit('openTags', 'none')">x</button>
    </div>
    <div class="mt-3">
      <div class="d-flex flex-column">
        <span v-for="item in filterTags()" :style='`background:${item.color};color:${item.textColor}`' @click="$emit('updateStatus', item.id)">
          {{item.name}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    props: {
      display: {
        type: String,
        default: 'none'
      },
      tagStatus: {
        type: [Number, String],
        default: 0
      },
      typeService: {
        type: [Number, String],
        default: ''
      }
    },
    computed: mapGetters(['getTagsAll']),
    methods: {
      filterTags (id = null) {
        // tipo de serviço 6 sem upload só 2 tags
        // 7 com upload]

        if (id) {
          return this.getTagsAll.filter(item => item.id == 6)
        }

        if(this.typeService == 6) {
          const rest =  this.getTagsAll.filter(item => item.id == 3 || item.id == 7)
          return rest
        }

        const result = this.getTagsAll.filter(item => item.id > 2 && item.id != 6 && item.id != 8 && item.id != 5 && item.id != 9)
        return result
      },
    },
  }
</script>

<style scoped>
.containerTag {
  z-index: 3;
  width: 200px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
  padding: 10px 10px 10px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.containerTag h2 {
  font-weight: 400;
  font-size: 14px;
  color: #5397CC;
  margin: 0 0 20px;
  z-index: 2;
}

span {
  border-radius: 18px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
  transition: .1s ease-in-out;
}

span:hover {
  padding: 11px 21px;
}

.close {
  color: #f65757;
  background: #f5f2f2;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 36px;
}
</style>
