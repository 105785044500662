<template>
  <div class="row m-auto col-md-12 d-flex align-content-center justify-content-end p-0">
      <div class="row">
        <div class="mx-2 my-3">
          <button
            type="button"
            class="btn btn_secondary"
            @click.prevent="$emit('closeModal')"
          >
            Cancelar
          </button>
        </div>
        <div class="mr-3 ml-2 my-3">
          <button
            type="button"
            @click.prevent="teste"
            class="btn btn_default d-flex justify-content-center"
          >
            <div v-if="!load">
               Salvar
            </div>

             <half-circle-spinner
              :animation-duration="1000"
              :size="30"
              color="#fff"
              v-else
            />
          </button>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";

export default {
  components: {
    HalfCircleSpinner,
  },
  props: ["load"],
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
