<template>
  <div>
    <h1>Relatórios</h1>
    <div class="btnImg" @click="openModal">
      <img src="@/assets/image/relatorios/mark.png" alt="mark" />
      <h1>Serviços Despachante</h1>
    </div>
    <half-circle-spinner
      v-if="load"
      :animation-duration="1000"
      :size="100"
      color="#fff"
      class="mx-auto"
    />
    <itemsRelatorioVue :items="currentData" @exportItem="exportItem" v-else />
    <modalRelatorioExtrairVue
      :tenants="getAllTenantsLogin"
      :service="serviceDespachanteCategoria"
      :status="status"
      :tags="tags"
      @percent="percent"
      @update="exctrator"
      @exctrator="exctrator"
    />
    <csvExportVue :dataJson="dataJson" />

    <!-- {{getAllTenantsLogin}} -->
    <!-- {{serviceDespachanteCategoria}} -->
  </div>
</template>

<script>
import modalRelatorioExtrairVue from "./modalRelatorioExtrair.vue";
import itemsRelatorioVue from "./itemsRelatorio.vue";
import csvExportVue from "./csvExport.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    modalRelatorioExtrairVue,
    itemsRelatorioVue,
    csvExportVue,
    HalfCircleSpinner
  },
  data() {
    return {
      status: [],
      tags: [],
      currentData: [],
      dataJson: [],
      load: false,
      values: 0
    };
  },
  computed: {
    ...mapGetters([
      "getAllTenantsLogin",
      "serviceDespachanteCategoria",
      "getUser",
      "get_tenant",
      "get_message"
    ]),
    ...mapMutations(["setStatusAll"])
  },
  mounted() {
    this.exctrator();
    if (this.status.length) return;
    this.getStatus();
    this.getTagsAll();
  },
  methods: {
    ...mapActions(["getServiceStatus", "getTags", "getExcel"]),
    getStatus() {
      this.getServiceStatus()
        .then(({ data }) => {
          const { result } = data;
          this.status = result;
          // console.log(result)
          // return
          // this.setStatusAll(result)
        })
        .catch(err => {
          console.log(err);
        });
    },

    getTagsAll() {
      this.getTags()
        .then(res => {
          this.tags = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    exctrator() {
      this.load = true;
      this.getExcel(
        this.getUser.profile == 2 && !this.get_tenant.isDispatcher
          ? this.getUser.profile + 2
          : this.getUser.profile + 1
      )
        .then(res => {
          const result = res.data.result;
          this.currentData = result;
          this.load = false;
          return;
        })
        .catch(err => {
          this.load = false;
          console.log(err);
        });
    },

    exportItem(data) {
      console.log(data);
      this.dataJson = data;
    },

    percent(value) {
      this.values = value;
    },

    openModal() {
      $("#extrair").modal("show");
    }
  },
  watch: {
    get_message: function() {
      if (
        this.get_message ==
        `11_${
          this.$store.getters.getUser.profile == 1
            ? null
            : this.$store.getters.tenantRequire.tenantId
        }`
      ) {
        this.exctrator();
      }
    }
  }
};
</script>

<style scoped>
h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.btnImg {
  height: 192px;
  width: 234px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #3f88c1;
  border-radius: 12px;
  margin: 28px 0;
  cursor: pointer;
}
.btnImg h1 {
  text-align: center;
  font-size: 17px;
}
</style>
