<template>
  <div>
    <div class="containerHeader">
      <h2>
        Documentos
        {{
          document.documents
            ? `Enviados: ${
                document.documents.filter(s => s.id !== null).length
              }/${document.documents.length}`
            : ":"
        }}
      </h2>
    </div>
    <div v-if="document.documents.length <= 0">
      <h2 class="text-center not">Nenhum Documento anexado</h2>
    </div>
    <div v-else class="w-50">
      <div
        v-for="(item, index) in document.documents"
        :class="
          item.id
            ? 'd-flex align-items-center'
            : 'd-flex align-items-center justify-content-between'
        "
        :key="item.name"
        @click="update(item)"
      >
        <div v-if="get_tenant.isDispatcher && item.id">
          <label class="containerCheck">
            <input
              type="checkbox"
              :checked="item.approve"
              :disabled="
                !get_tenant.isDispatcher || paramsRouter != 'solicitacao'
              "
              @click="update(item)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="d-flex align-items-center justify-content-center boxItem cursorP"
        >
          <font-awesome-icon
            icon="fas-regular fa-check"
            class="text-success mr-2"
            v-if="item.id && item.approve"
          />
          <font-awesome-icon
            icon="fas-solid fa-xmark"
            class="text-danger mr-2"
            v-else
          />
          <img src="@/assets/image/iconesUpload/2.svg" alt="pdf" />
          <p>
            {{
              item.documentName.substring(item.documentName.indexOf("_") + 1)
            }}
          </p>
        </div>

        <div
          v-if="
            !item.approve && !get_tenant.isDispatcher && document.tagStatus == 2
          "
        >
          <!-- {{item.operated && !item.approve}} -->

          <button
            class="btnIcons defaultUpload"
            @click="openFile(item.sequence)"
            v-if="
              !item.operated &&
                item.documentName == item.documentNameDefault &&
                (!fileSend[index] || fileSend[index].name == '')
            "
          >
            <font-awesome-icon icon="fas-solid fa-arrow-up-from-bracket" />
          </button>
          <button
            class="btnIcons bg-danger"
            @click="deleteItemDoc(item)"
            v-if="
              item.operated && item.documentName !== item.documentNameDefault
            "
          >
            <font-awesome-icon icon="fas-regular fa-trash" />
          </button>
          <!-- <div v-if="!fileSend[index] && fileSend[index].name === ''">
      </div> -->

          <div v-if="item.documentName == item.documentNameDefault">
            <div
              v-if="fileSend[index] && fileSend[index].name != ''"
              class="d-flex"
            >
              <div
                class="d-flex mx-2 align-items-center justify-content-center"
              >
                <font-awesome-icon
                  class="colorIcon"
                  icon="fas-regular fa-circle-info"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="fileSend[index].name"
                />
              </div>
              <button
                v-if="fileSend[index] && fileSend[index].name != ''"
                class="btnIcons bg-danger"
                @click="closeItem(item.sequence)"
              >
                <font-awesome-icon icon="fas-regular fa-trash" />
              </button>
            </div>
          </div>

          <div>
            <half-circle-spinner
              v-if="loadFile"
              :animation-duration="1000"
              :size="40"
              color="red"
              class="mx-2"
            />
            <!-- <div v-else> -->
            <!-- <button class="btnIcons defaultUpload" @click="openFile(item.sequence)" v-if="!item.approve && !item.operated">
              <font-awesome-icon icon="fas-solid fa-arrow-up-from-bracket" />
            </button> -->
            <!-- <button class="btnIcons bg-danger" @click="deleteItemDoc(item)" v-else>
              <font-awesome-icon icon="fas-regular fa-trash" />
            </button> -->
            <!-- </div> -->
          </div>
        </div>

        <input
          type="file"
          name="file"
          ref="files"
          accept="application/pdf"
          class="none"
          @change="filesItem($event, item.sequence)"
          :id="item.sequence"
        />
      </div>
    </div>
    <!-- {{getDataItemDetails.id}} -->
    <button
      class="btnFile send"
      @click="dowloadFiles(document)"
      v-if="paramsRouter == 'solicitacao'"
    >
      Baixar Arquivos
    </button>
    <button
      class="btnFile send mx-2"
      v-if="allFiles.length"
      @click="ServiceDispatcherCreate"
    >
      Upload
    </button>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: {
    document: {
      type: [Array, Object],
      default: []
    }
  },
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      files: [],
      containerItens: [],
      deleteItemLoad: false,
      loadSendFile: false,
      loadFile: false,
      allFiles: [],
      filesItens: [],
      fileSend: []
    };
  },
  computed: {
    ...mapGetters([
      "get_tenant",
      "getTenantIdCurrentDelete",
      "getOrderServiceId",
      "getDataItemDetails"
    ]),
    paramsRouter() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapMutations(["setDetailsServiceDespachante"]),
    ...mapActions(["getFilesDowload", "deleteServiceDoc", "getDetails"]),

    createItens() {
      if (this.filesItens.length > 0) return;
      const itens = this.document.documents.map(x => ({
        sequence: x.sequence,
        inputFile: x.id ? x.documentName : "",
        name: x.id ? x.documentName : ""
      }));

      this.filesItens = itens;
    },

    update(itemDocument) {
      if (this.paramsRouter != "solicitacao") return;

      let document = [...this.document.documents];
      let newdocument = [...this.document.documents];
      let filter = [];

      document.forEach(item => {
        if (item == itemDocument) {
          const docFilter = newdocument.filter(item => item != itemDocument);
          filter.push(docFilter);
          let aproveChange = (item.approve = !item.approve);
          filter.push({
            id: itemDocument.id,
            documentName: item.documentName,
            approve: aproveChange
          });
        }
      });

      this.$emit("itensDocument", newdocument);
    },

    closeItem(item) {
      let newValue = [...this.fileSend];
      newValue.forEach(items => {
        if (item == items.sequence) {
          items.inputFile = "";
          items.name = "";
        }
      });

      this.allFiles = this.allFiles.filter(s => !s.name.includes(`${item}* `));

      this.$refs.files.forEach((itemInput, index) => {
        if (item == index + 1) {
          itemInput.value = "";
        }
      });

      this.fileSend = newValue;
    },

    async ServiceDispatcherCreate() {
      this.loadFile = true;
      let data = new FormData();
      let file = this.allFiles;
      for (let i = 0; i < file.length; i++) {
        data.append(file[i].name, file[i]);
      }

      try {
        const dataItens = await HTTP.post(
          `services/app/OrderServiceFile/Create?orderServiceId=${this.document.orderServiceId}`,
          data,
          {
            headers: {
              Authorization: `bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        this.files = [];
        (this.allFiles = []),
          (this.filesItens = []),
          (this.fileSend = []),
          (this.loadFile = false);
        $("#modal_info").modal("hide");
        this.toast("Arquivos enviado com sucesso", this.$toast);
      } catch (error) {
        console.error(error);
        this.loadSendFile = this.load = this.loadFile = false;
        this.toast(error.response.data.error.message, this.$toast.error);
      }
    },

    changeInputValue() {
      // console.log(this.files())
      return;
      let dataForm = new FormData();
      for (let file of this.$refs.files.files) {
        dataForm.append(`file`, file);
      }

      setTimeout(() => {}, 1000);
    },

    openFile(id) {
      this.$refs.files.forEach(element => {
        if (id == element.id) {
          element.click();
        }
      });
      this.createItens();
    },

    filesItem(event, index) {
      const { files, value } = event.target;
      const file = event.target.files;
      const renameFile = new File(
        event.target.files,
        `${index}* ${event.target.files[0].name}`
      );
      this.allFiles.push(renameFile);
      let itens = [...this.filesItens];
      if (value.length > 0) {
        itens.forEach(item => {
          if (item.sequence === index) {
            item.inputFile = file;
            item.name = file[0].name;
          }
        });
      }
      this.fileSend = itens;
      // console.log(itens)
    },

    clearItemDocument(paramsItemId) {
      const filterValue = this.document.documents.filter(
        item => item.id != paramsItemId
      );
      this.document.documents = filterValue;
      console.log(filterValue);
    },

    async deleteItemDoc({ id }) {
      this.deleteItemLoad = true;
      try {
        const result = await this.deleteServiceDoc(id);
        // this.clearItemDocument(id);
        this.deleteItemLoad = false;
        this.toast("Deletado com sucesso", this.$toast);
        this.updateDetails();
      } catch (error) {
        this.deleteItemLoad = false;
        this.toast(
          "Ops não foi possivel deletar esse arquivo",
          this.$toast.error
        );
      }
    },

    async dowloadFiles({ orderServiceId }) {
      // tenantId: this.getTenantIdCurrentDelete
      const item = {
        tenantId: this.getTenantIdCurrentDelete,
        orderServiceId
      };
      // console.log(orderServiceId, this.getTenantIdCurrentDelete)
      // return
      this.toast("Iniciando Download", this.$toast);
      try {
        const { data } = await this.getFilesDowload(item);
        console.log(data);
        const { image64, nameFile, mimeType } = data.result;

        let a = document.createElement("a");
        a.href = `data:${mimeType};base64,${image64}`;
        a.download = nameFile;
        a.click();
      } catch (error) {
        console.log("ops");
      }
    },

    updateDetails() {
      // const tenantId = item.tenantId ? item.tenantId : this.tenantRequire.tenantId
      // const tenantId = this.getDataItemDetails.tenantId ? this.getDataItemDetails.tenantId : "";

      this.getDetails(this.getDataItemDetails)
        .then(({ data }) => {
          this.setDetailsServiceDespachante({
            result: data.result,
            load: false
          });
          this.allFiles = [];
          this.filesItens = [];
          this.fileSend = [];
        })
        .catch(err => {
          console.log(err);
          this.setDetailsServiceDespachante({ result: {}, load: false });
          this.toast(err.response.data.error.message, this.$toast.error);
        });
    }
  }
};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #98c4e6;
  outline: none;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  border: none;
  background: none;
}

.containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: #eee;
}

.containerCheck:hover input ~ .checkmark {
  background-color: #ccc;
}

.containerCheck input:checked ~ .checkmark {
  background-color: #25d366;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerCheck input:checked ~ .checkmark:after {
  display: block;
}

.containerCheck .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.boxItem {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  margin: 4px 0px;
}

.boxItem p {
  font-weight: 400;
  font-size: 14px;
  color: #797984;
  margin: 0 0 0 10px;
}

.btnFile {
  background: #3f88c1;
  border-radius: 4px;
  height: 40px;
  border: none;
  color: #fff;
  padding: 10px;
  margin: 10px 0;
}

.deleteBtn {
  padding: 10px;
  background: #ff000000;
  outline: none;
  border-radius: 6px;
  color: #fff;
  transition: 0.1s ease-in-out;
  border: none;
}

.containerPDF {
  background: #b9d0e9;
  background: #dce6f1;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 7px;
  padding: 10px;
}

.containerPDF span {
  color: #ffffff;
  color: #1b60a8;
  font-size: 14px;
  font-weight: 500;
}

.closePDF {
  border: none;
  background: #e20613;
  color: #fff;
  border-radius: 7px;
  padding: 4px 8px;
  cursor: pointer;
}

.deleteBtn:hover {
  background: #f65757;
}

.send {
  background: #f38235;
}

.none {
  display: none;
}

.btnIcons {
  border: none;
  color: #fff;
  border-radius: 5px;
  transition: 0.1s ease-in-out;
  outline: none;
  padding: 3px 13px;
  padding: 7px 11px;
  margin: 10px;
}

.defaultUpload {
  background: #e1a438;
}

.defaultUpload:hover {
  background: #f3aa2b;
}

.colorIcon {
  color: #e1a438;
}

.cursorP {
  cursor: pointer;
}
</style>
