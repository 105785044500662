<template>
  <div class="allCont">
    <div class="d-flex align-items-center justify-content-between">
      <h1 class="title">
        {{
          paramsRouter != "solicitacao"
            ? getAccompanyServiceDespachante.result.serviceName
            : "Solicitação"
        }}
        <span>Acompanhar</span>
      </h1>

      <inputFilterVue
        :positionIcon="'justify-content-end'"
        :positionContainer="this.sizeWindow < 767 ?'left' : ''"
        :show="showModal"
        @openFilter="showModalFilter"
        @filter="filter"
        @filterClear="filterClear"
      >
        <div
          v-if="paramsRouter === 'solicitacao'"
          class="d-flex align-content-center flex-column"
        >
          <label class="labels" for="Status">Serviços</label>
          <select class="select" name="Status" id="Status" v-model="service">
            <option value=""></option>
            <option
              v-for="item in serviceDespachanteCategoria"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>

        <div class="d-flex align-content-center flex-column">
          <label class="labels" for="pedido">Pedido</label>
          <input class="input" id="pedido" type="number" v-model="orderId" />
        </div>
        <div class="d-flex align-content-center flex-column">
          <label class="labels" for="Placa">Placa</label>

          <the-mask
            class="input"
            :mask="'AAA-#G##'"
            :placeholder="'AAA-#G##'"
            :name="'Placa'"
            :tokens="hexTokens"
            id="Placa"
            @blur.native="getVehiclePlate($event)"
            :value="vehiclePlate"
          />

          <!-- <input
            class="input"
            id="placa"
            type="number"
            v-model="vehiclePlate"
          /> -->
        </div>
        <label class="labels" for="notification">Notificação</label>
        <input
          type="checkbox"
          class="checkbox mb-3"
          id="notification"
          v-model="notification"
        />

        <div
          class="d-flex align-content-center flex-column"
          v-if="paramsRouter != 'solicitacao'"
        >
          <div class="d-flex align-items-center justify-content-around">
            <div class="d-flex align-items-center justify-content-center">
              <label class="mb-0 mr-2 labels" for="cpf">CPF</label>
              <input type="radio" value="cpf" v-model="fieldFilter" id="cpf" />
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <label class="mb-0 mr-2 labels" for="name">Nome</label>
              <input
                type="radio"
                value="name"
                v-model="fieldFilter"
                id="name"
              />
            </div>
          </div>

          <the-mask
            type="text"
            class="input"
            :placeholder="
              fieldFilter === 'name' ? 'Nome do cliente' : 'CPF do cliente'
            "
            name="fieldName"
            :mask="fieldFilter == 'cpf' ? ['###.###.###-##'] : returnItens()"
            :tokens="hexTokens"
            v-model="valueNameAndCpf"
          />
        </div>

        <label class="labels" for="Status">Status</label>
        <select
          class="select"
          name="Status"
          id="Status"
          v-model="tagValue"
          @change="filterTag"
        >
          <option value=""></option>
          <option
            v-for="item in statusFilter"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>

        <div
          v-if="getTagFilter.length > 0 && tagValue"
          class="d-flex align-content-center flex-column"
        >
          <label class="labels" for="Tag">Tag</label>
          <select class="select" id="Tag" v-model="statusValeu">
            <option value=""></option>
            <option
              v-for="item in getTagFilter"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>

        <div
          v-if="paramsRouter === 'solicitacao'"
          class="d-flex align-content-center flex-column"
        >
          <label class="labels" for="Status">Unidade</label>
          <select class="select" v-model="tenant">
            <option value=""></option>
            <option
              :value="item.id"
              v-for="item in getAllTenantsLogin"
              :key="item.id"
              >{{ item.tenancyName }}</option
            >
          </select>
        </div>

        <label class="labels" for="dateInit">Data Inicial</label>
        <input class="input" type="date" id="dateInit" v-model="dateInit" />

        <label class="labels" for="dateFinish">Data Final</label>
        <input class="input" type="date" id="dateFinish" v-model="dataFinish" />
      </inputFilterVue>
    </div>
    <half-circle-spinner
      :animation-duration="1000"
      :size="100"
      color="#fff"
      class="my-5 mx-auto"
      v-if="
        getAccompanyServiceDespachanteController.load ||
          getAccompanyServiceDespachante.load
      "
    />
    <div v-else-if="paramsRouter != 'solicitacao'">
      <containerCard
        @openModal="openModal"
        :items="getAccompanyServiceDespachante.result"
        :itemAccompany="itemAccompany"
      />
      <modalPedido
        :name="getAccompanyServiceDespachante.result.serviceName"
        :item="itemAccompany"
        :itemsAll="getAccompanyServiceDespachante.result"
      />
    </div>
    <containerItems
      :items="getAccompanyServiceDespachanteController.result"
      :itemAccompany="itemAccompany"
      @openModal="openModal"
      v-else
    />
    <SubModal :itemAccompany="itemAccompany" @filter="filter" />
    <modalDegustacao/>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { HalfCircleSpinner } from "epic-spinners";
import containerCard from "./containerCard/container.vue";
import containerItems from "./containerItems/itemComponent.vue";
import modalPedido from "./modal/modalAcompanhar.vue";
import SubModal from "./modal/modalSub.vue";
import modalDegustacao from "@/components/modal/modalDegustacao";
import inputFilterVue from "../filters/dropdownFilter.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    HalfCircleSpinner,
    containerCard,
    modalPedido,
    SubModal,
    containerItems,
    inputFilterVue,
    TheMask,
    modalDegustacao
  },
  data() {
    return {
      itemAccompany: {},
      sizeWindow: 0,
      orderId: "",
      vehiclePlate: "",
      service: "",
      statusValeu: "",
      tagValue: "",
      dateInit: "",
      dataFinish: "",
      valueNameAndCpf: "",
      fieldFilter: "cpf",
      notification: false,
      tenant: "",
      getTagFilter: [],
      showModal: false,
      statusFilter: [
        {
          id: 1,
          name: "Pendente"
        },
        {
          id: 2,
          name: "Em Progresso"
        },
        {
          id: 3,
          name: "Concluido"
        }
      ],
      hexTokens: {
        G: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() }, // numero com Letras Mai
        "#": { pattern: /\d/ }, // Numero
        X: { pattern: /[0-9a-zA-Z]/ }, // Numeros e letras
        A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
        T: { pattern: /[0-9a-zA-Z-@]/ },
        "!": { escape: true }
      }
    };
  },
  computed: {
    ...mapGetters([
      "getAllTenantsLogin",
      "getAccompanyServiceDespachante",
      "getAccompanyServiceDespachanteController",
      "tenantRequire",
      "getTagsAll",
      "getStatusAll",
      "getPage",
      "serviceDespachanteCategoria",
      "getCloseFilter",
      "get_message"
    ]),
    paramsRouter() {
      return this.$route.params.id;
    }
  },
  methods: {
    getVehiclePlate(event) {
      this.vehiclePlate = event.target.value;
    },
    ...mapMutations([
      "setDetailsServiceDespachante",
      "setItemCardService",
      "setTenantIdCurrentDelete",
      "setAccompanyServiceDespachante",
      "setNumberOfPages",
      "setAccompanyServiceDespachanteController",
      "setUrlFilter",
      "setDataItemDetails",
      "setPage"
    ]),
    ...mapActions([
      "getDetails",
      "getAllAccompanyFilter",
      "getAllControllerFilter",
      "getTagsFilter"
    ]),
    showModalFilter(param) {
      if (param && this.showModal) {
        this.showModal = false;
      } else if (!param && this.showModal) {
        this.showModal = param;
      } else {
        this.showModal = param;
      }
    },

    returnItens() {
      let forItens = [];
      for (let i = 0; i <= 99; i++) {
        forItens.push("T");
      }
      return forItens.join("");
    },
    openModalData(item) {
      this.setDataItemDetails(item);
      // const tenantId = item.tenantId ? item.tenantId : this.tenantRequire.tenantId
      const tenantId = item.tenantId ? item.tenantId : "";
      this.setTenantIdCurrentDelete(tenantId);
      this.getDetails(item)
        .then(({ data }) => {
          this.setDetailsServiceDespachante({
            result: data.result,
            load: false
          });
        })
        .catch(err => {
          console.log(err);
          this.setDetailsServiceDespachante({ result: {}, load: false });
          this.toast(err.response.data.error.message, this.$toast.error);
        });
    },
    filterClear() {
      this.service = "";
      this.statusValeu = "";
      this.tagValue = "";
      this.dateInit = "";
      this.dataFinish = "";
      this.tenant = "";
      this.orderId = "";
      this.vehiclePlate = "";
      this.valueNameAndCpf = "";
      this.notification = false;
      this.getTagFilter = [];
      this.filter();
      this.setNumberOfPages(1);
      this.setPage(1);
    },
    async filterTag() {
      const { tenantRequire, tagValue } = this;

      if (!tagValue) return (this.getTagFilter = []);

      const tenantId = tenantRequire.tenantId ? tenantRequire.tenantId : "";
      try {
        const { data } = await this.getTagsFilter({ tenantId, tagValue });
        const { result } = data;
        this.getTagFilter = result;
      } catch (error) {
        console.log(error);
      }
    },
    openModal(nameModal, item) {
      this.itemAccompany = item;
      this.openModalData(item);
      this.setItemCardService(item);
      if (nameModal == "modal_info" && this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");;
      } else {
        $(`#${nameModal}`).modal("show");
      }
    },
    async filter() {
      const {
        statusValeu,
        tagValue,
        dateInit,
        dataFinish,
        getPage,
        tenantRequire,
        paramsRouter,
        serviceDespachanteCategoria,
        service,
        tenant,
        notification,
        orderId,
        vehiclePlate,
        valueNameAndCpf,
        fieldFilter
      } = this;
      const tenantId = tenant ? tenant : "";
      const id = paramsRouter !== "solicitacao" ? paramsRouter : service;

      const urlFilter = `?${id ? `&ServiceId=${id}` : ""}
      ${orderId ? `&OrderId=${orderId}` : ""}${
        statusValeu ? `&TagStatus=${statusValeu}` : ""
      }${tagValue ? `&Status=${tagValue}` : ""}${
        dateInit ? `&DateInitial=${dateInit}` : ""
      }${dataFinish ? `&DateFinish=${dataFinish}` : ""}${
        notification ? `&OrderByNotification=${notification}` : ""
      }${tenantId ? `&TenantId=${tenantId}` : ""}${
        vehiclePlate ? `&VehiclePlate=${vehiclePlate}` : ""
      }${
        valueNameAndCpf.length
          ? fieldFilter === "name"
            ? `&NameClient=${valueNameAndCpf}`
            : `&Cpf=${valueNameAndCpf}`
          : ""
      }${getPage ? `&pageCurrent=${getPage}` : ""}`;

      this.setUrlFilter(urlFilter);
      if (this.paramsRouter != "solicitacao") {
        try {
          this.setAccompanyServiceDespachante({ result: {}, load: true });
          console.log(urlFilter);
          const { data } = await this.getAllAccompanyFilter(
            decodeURI(urlFilter)
          );
          const { result } = data;
          this.setAccompanyServiceDespachante({ result: result, load: false });
          this.setNumberOfPages(result.numberOfPages);
          this.showModal = false;
        } catch (error) {
          this.toast(error.response.data.error.message, this.$toast.error);
          this.setAccompanyServiceDespachante({ result: {}, load: false });
        }
        return;
      }

      try {
        this.setAccompanyServiceDespachanteController({
          result: {},
          load: true
        });
        const { data } = await this.getAllControllerFilter(urlFilter);
        const { result } = data;
        this.setAccompanyServiceDespachanteController({
          result: result,
          load: false
        });
        this.setNumberOfPages(result.numberOfPages);
        this.showModal = false;
      } catch (error) {
        this.setAccompanyServiceDespachanteController({
          result: {},
          load: false
        });
        this.toast(error.response.data.error.message, this.$toast.error);
      }
    },

    async getTagRequest() {
      try {
        const result = await this.getTags();
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    getPage() {
      this.filter();
    },
    getCloseFilter(valeu) {
      if (value) {
        this.showModal = false;
      }
    },
    fieldFilter() {
      this.valueNameAndCpf = "";
    },
    get_message() {
      if (
        this.get_message == `10_${this.$store.getters.tenantRequire.tenantId}`
      ) {
        this.filter();
        return;
      }
    },
    "$route.params.id": function(id) {
      if (this.$route.params.id == "relatorios") return;
      this.filter();
    }
  },
  mounted() {
    this.filterClear();
    this.sizeWindow = $(window).width();
  }
};
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 700;
}

.title {
  margin: 24px 0;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.title span {
  font-weight: 600;
  font-size: 16px;
  color: #98c4e6;
}

.containerForms {
  width: 369px;
}

.label_default {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.btn_default {
  height: 40px;
  border: none;
  margin-top: 25px;
  width: 100%;
  background: #f38235;
  border-radius: 6px;
  width: 130px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  transition: 0.2s ease-in-out;
  outline: none;
}

.btn_default:hover {
  background: rgba(216, 109, 37, 0.884);
}

.form-control {
  background: #ffffff;
  border: 1px solid #98c4e6;
  border-radius: 6px;
  height: 40px;
  background-color: #f6f6f6 !important;
  font-weight: 500 !important;
  color: #868686;
}

::-webkit-input-placeholder {
  color: #868686;
  opacity: 0.5;
}

.borderBt {
  border-top: 2px solid #3f88c1;
}
@media screen and (max-width:767px){
  .allCont{
    padding-left: 15px;
    padding-right: 15px;
  }
  .title{
    font-size: 22px;
    padding-left: 15px;
    width: 100%;
  }
}
</style>
