<template>
<div>
  <div class="container">
    <div v-if="items.length <= 0">
      <h2 class="text-center notFoundText">
        Nenhuma Solicitação de Relatório Encontrado
      </h2>
    </div>
    <div v-else>
      <div class="row" v-if="this.sizeWindow > 767">
        <div class="col-2">
          <h4>Data inicial</h4>
        </div>
        <div class="col-2">
          <h4>Data Final</h4>
        </div>
        <div class="col-2">
          <h4>Serviço</h4>
        </div>
        <div class="col-2">
          <h4>Consulta</h4>
        </div>
        <div class="col-2">
          <h4>Status e Tag</h4>
        </div>
      </div>
      <div>
        <div class="row containers extrator mb-3" v-for="item in items" :key="item.tag">
          <div class="col-2 m-auto">
            <!-- {{item}} -->
            <h4 v-if="sizeWindow <= 767">Data inicial</h4>
            <h4>
              {{
                  `${
                    item.parameters.dateInitial
                      ? item.parameters.dateInitial.substr(0, 10)
                      : "Não informado"
                  }`
                }}
            </h4>
          </div>
          <div class="col-2 m-auto">
            <!-- {{item}} -->
            <h4 v-if="sizeWindow <= 767">Data Final</h4>
            <h4>{{ `${item.parameters.dateFinale ? item.parameters.dateFinale.substr(0, 10) : "Não informado" }` }}</h4>
          </div>
          <div class="col-2 d-flex justify-content-center align-items-center">
            <h4 v-if="sizeWindow <= 767">Serviço</h4>
            <h4>
              {{
                  item.parameters.serviceName
                    ? item.parameters.serviceName
                    : "Não informado"
                }}
            </h4>
          </div>
          <div class="col-2 m-auto">
            <h4 v-if="sizeWindow <= 767">Consulta</h4>
            <h4>{{ item.created }}</h4>
          </div>
          <div class="col-2 d-flex justify-content-center align-items-center">
            <h4 v-if="sizeWindow <= 767">Status e Tag</h4>
            <div v-if="
                  item.parameters.statusTags &&
                    item.parameters.statusTags.length
                " class="col-md-2 form-group form-outline" id="tooltip">
              <i class="fas fa-info-circle mt-3 text-white" data-toggle="tooltip" data-placement="right" :data-original-title="
                    getTooltipStatus(item.parameters.statusTags)
                  ">
              </i>
            </div>
            <h4 v-else>Não Informado</h4>
          </div>

          <div class="col-2 d-flex justify-content-around align-items-center" v-if="item.report.length">
            <csvExportFranq :dataJson="item.report" :visible="true" v-if="getUser.profile == 2 && !get_tenant.isDispatcher" />
            <csvExportVue :dataJson="item.report" :visible="true" v-else />
          </div>
          <div class="col-2 d-flex justify-content-around align-items-center" v-else-if="!item.isComplete">
            <h4>Aguarde...</h4>
          </div>
          <div class="col-2 d-flex justify-content-around align-items-center" v-else>
            <button @click="error" class="bg-danger text-white btnErro">
              Ops
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import csvExportVue from "./csvExport.vue";
import csvExportFranq from "./csvExportFranq.vue";

import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";
export default {
  data() {
    return {
      sizeWindow: 0,
    }
  },
  components: {
    csvExportVue,
    csvExportFranq
  },
  props: {
    items: {
      type: [Object, Array],
      default: {}
      // require: true
    }
  },
  computed: mapGetters(["getTagsAll", "getUser", "get_tenant"]),
  methods: {
    ...mapActions(["getTags"]),
    ...mapMutations(["setTagsAll"]),
    filterTags(tagStatus) {
      const result = this.getTagsAll.filter(item =>
        tagStatus.includes(item.id)
      );
      return result;
    },
    enableTooltip() {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip({
          html: true,
          container: "body"
        });
      });
    },
    getTooltipStatus(listGroups) {
      let templateToolTip = "";
      listGroups.forEach(s => {
        templateToolTip = templateToolTip.concat(
          `<div class='d-flex justify-content-start'>${s.groupName}</div>`
        );

        templateToolTip = templateToolTip.concat(`<p class='text-left'>`);
        this.filterTags(s.statusTags).forEach(ss => {
          templateToolTip = templateToolTip.concat(`${ss.name}<br />`);
        });
        templateToolTip = templateToolTip.concat(`</p>`);
      });
      return templateToolTip;
    },
    error() {
      this.toast(
        "Não foi possível obter dados com os parâmetros informados nessa consulta",
        this.$toast.error
      );
    },
    async getTagRequest() {
      try {
        const result = await this.getTags();
        const tags = result.data.result;
        this.setTagsAll(tags);
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.enableTooltip();
    this.getTagRequest();
  }
};
</script>

<style scoped>
.containers {
  margin-top: 10px;
  background: #3f88c1;
  border-radius: 8px;
  height: 56px;
  transition: 0.1s ease-in-out;
}

.container h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.containers:first-child {
  margin-top: 20px;
}

.containers:hover {
  background: #2474b2;
  border: 1px solid #3f88c1;
}

.containers h4 {
  font-weight: 600;
  font-size: 16px;
}

.unid h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.notFoundText {
  font-weight: 600;
  margin-top: 100px;
  font-size: 16px;
  color: #98c4e6;
}

span {
  background: #ffbc02;
  border-radius: 18px;
  color: #fff;
  padding: 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  /* color: #000;
  padding: 10px 20px; */
}

.btnErro {
  height: 40px;
  width: 115px;
  border-radius: 5px;
  border: 2px solid;
  cursor: pointer;
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  border: none;
}

@media screen and (max-width:767px) {
  .containers {
    flex-direction: column;
    flex-wrap: unset;
    margin-top: 0 !important;
    height: auto;
    padding: 8px;
  }

  .containers .col-2 {
    max-width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
}
</style>
