<template>
<div>
  <div class="container">
    <div class="row" v-if="this.sizeWindow > 767">
      <div class="col-2">
        <h4>PEDIDO</h4>
      </div>
      <div class="col-2">
        <h4>PLACA</h4>
      </div>
      <div class="col-2">
        <h4>STATUS</h4>
      </div>
      <div class="col-3">
        <h4>TAG</h4>
      </div>
      <div class="col-3">
        <h4>DATA/HORA</h4>
      </div>
    </div>
    <div v-if="items.resultService.length <= 0">
      <h2 class="text-center notFoundText">Nenhuma Solicitação Encontrada</h2>
    </div>
    <div v-else class="rowContCont">
      <div class="row containers" v-for="item in items.resultService" :key="item.tag">
        <div class="col-2 m-auto d-flex align-items-center justify-content-center m-auto rightBorder ">
          <h4 v-if="sizeWindow <= 767">Pedido:</h4>
          <h4>#{{ item.orderId }}</h4>
        </div>
        <div class="col-2 unid d-flex align-items-center justify-content-center m-auto rightBorder">
          <h4 v-if="sizeWindow <= 767">Placa:</h4>
          <h4>
            {{
                item.vehiclePlate ? item.vehiclePlate : "Placa não informada"
              }}
          </h4>
        </div>
        <div class="col-2 m-auto d-flex align-items-center justify-content-center m-auto rightBorder">
          <h4>Status:</h4>
          <h4>{{ status(item.status) }}</h4>
        </div>
        <div class="col-3 m-auto d-flex align-items-center justify-content-center m-auto rightBorder">
          <h4 v-if="sizeWindow <= 767">Tag:</h4>
          <span :style="`background:${filterTags(item.tagStatus)[0].color};color:${filterTags(item.tagStatus)[0].textColor}`">
            {{ filterTags(item.tagStatus)[0].name }}
          </span>
        </div>
        <div class="col row d-flex justify-content-center d-flex align-items-center justify-content-center m-auto rightBorder" id="iconsRightBorder">
          <h4 v-if="sizeWindow <= 767">D/H:</h4>
          <h4 id="dateOp" class="col">{{ item.dateOperation.split(" ")[0] }}</h4>
          <h4 class="mx-2 col-3">{{ item.dateOperation.split(" ")[1] }}</h4>
          <button class="max col-2 p-0 btnExpand " @click="$emit('openModal', 'modal_pedido', item, true)">
            <p v-if="sizeWindow <= 767">Expandir</p>
            <img v-if="sizeWindow > 767" src="@/assets/image/icones/arrows-maximize.png" alt="arrows" />
          </button>
          <div class="col-1 d-flex align-items-center justify-content-center m-auto p-0 notificationContainer">
            <svg v-show="item.notification" xmlns="http://www.w3.org/2000/svg" fill="#ffbc02" viewBox="0 0 448 512">
              <path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z" /></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters
} from "vuex";
export default {
  data() {
    return {
      sizeWindow: 0,
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  props: {
    items: {
      type: Object,
      default: {},
      require: true
    }
  },
  computed: mapGetters(["getTagsAll"]),
  methods: {
    filterTags(tagStatus) {
      const result = this.getTagsAll.filter(item => item.id == tagStatus);
      return result;
    },

    status(type) {
      const status = {
        0: "Pedido em Aberto",
        1: "Pendente",
        2: "Em Progresso",
        3: "Concluido",
        default: "Sem status"
      };
      return status[type] || status.default;
    },

    tag(tagStatus) {
      const tag = {
        1: {
          id: 1,
          name: "Pendente",
          color: " #FFF",
          textColor: "#2474B2"
        },
        2: {
          id: 2,
          name: "Aguardando Documentos",
          color: " #F65757",
          textColor: "#FFF"
        },
        3: {
          id: 3,
          name: "Em Andamento",
          color: " #F38235",
          textColor: "#FFF"
        },
        4: {
          id: 4,
          name: "Agendado",
          color: " #5DADEC",
          textColor: "#FFF"
        },
        5: {
          id: 5,
          name: "Comprovante Ok",
          color: " #25D366",
          textColor: "#FFF"
        }
      };

      return tag[tagStatus] || tag.default;
    }
  }
};
</script>

<style scoped>
.container h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.containers:first-child {
  margin-top: 20px;
}

.containers {
  margin-top: 10px;
  background: #3f88c1;
  border-radius: 8px;
  min-height: 56px;
}

.containers h4 {
  font-weight: 600;
  font-size: 13px;
}

/* .containers div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #f5f9fd26;
  height: 80%;
  margin: auto;
} */

.rightBorder {
  height: 80%;
  border-right: 2px solid #f5f9fd26;
}

span {
  background: #ffbc02;
  border-radius: 18px;
  color: #fff;
  padding: 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  /* color: #000;
  padding: 10px 20px; */
}

.green {
  background: #25d366;
  height: 36px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  height: 36px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5dadec;
}

.max {
  background: none;
  border: none;
  outline: none;
}

.unid {
  margin: 0;
}

.unid h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.notFoundText {
  font-weight: 600;
  margin-top: 100px;
  font-size: 16px;
  color: #98c4e6;
}

.notification {
  height: 9px !important;
  width: 9px !important;
  border-radius: 50%;
  background-color: #f38235 !important;
  padding: 0px !important;
}

.btnExpand {
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 12px 10px !important;
  background: #f38235;
  color: #fff;
}

@media (min-width: 768px) {
  .btnExpand {
    background: none !important;
  }

  .max {
    background: none !important;
    border: none;
    outline: none;
  }
}

@media screen and (max-width:767px) {


  .containers {
    display: flex;
    flex-direction: column;
    flex-wrap: unset;
    min-height: 305px;
    width: 100%;
    padding: 5px;
  }

  .rightBorder {
    border: none;
    max-width: 100%;
    justify-content: space-between !important;
    min-height: 45px
  }

  .rowContCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .containers h4 {
    font-weight: 600 !important;
    font-size: 17px;
  }

  #iconsRightBorder {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  #dateOp {
    min-width: 94px;
  }

  .max {
    margin-top: 4px;
    display: flex;
    min-width: 80%;
    border-radius: 8px;
    background-color: rgb(243, 130, 53);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 23px;
  }

  .max p {
    margin: 0;
    font-size: 14px;
    color: #FFFF;
  }

  .notificationContainer {
    margin: 0 !important;
  }

  .notificationContainer svg {
    width: 80%;
  }
}

@media screen and (max-width:400px) {
  .containers h4 {
    font-size: 14px;
  }
}
</style>
