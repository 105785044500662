<template>
  <Modal
    :modalId="`modal_info`"
    :modalTitle="getdetailsServiceDespachante.result.serviceName"
    :load="getdetailsServiceDespachante.load"
    :typeLg="'modal_wd'"
    @closeModal="closeModal"
  >
    <h2>
      {{
        getdetailsServiceDespachante.result.vehiclePlate
          ? `Placa: ${getdetailsServiceDespachante.result.vehiclePlate}`
          : "Placa não informada"
      }}
    </h2>
    <div v-if="get_tenant.isDispatcher" class="d-flex align-items-center">
      <div>
        <button class="edit" @click="inputChange = !inputChange">
          <font-awesome-icon icon="fas-solid fa-pen-to-square" />
        </button>
        <the-mask
          v-if="inputChange"
          type="text"
          class="inputEdit"
          v-model="valueInput"
          ref="input"
          mask="AAA-#G##"
          placeholder="Nova Placa"
          :tokens="hexTokens"
        />
      </div>
      <button
        class="save mx-2 p-2"
        v-if="inputChange && valueInput.length"
        @click="savePlate"
        :disabled="loadSendPlate"
      >
        <half-circle-spinner
          v-if="loadSendPlate"
          :animation-duration="1000"
          :size="15"
          color="#fff"
          class="mx-2"
        />
        <div v-else>
          Salvar
          <font-awesome-icon
            icon="fas-regular fa-check"
            class="text-success mr-2"
          />
        </div>
      </button>
    </div>

    <div
      class="d-flex align-items-center"
      v-if="getdetailsServiceDespachante.result.client"
    >
      <h2 class="mr-2">
        Nome: {{ getdetailsServiceDespachante.result.client.name }}
      </h2>
      <h2 class="mx-2">
        CPF: {{ getdetailsServiceDespachante.result.client.cpf }}
      </h2>
      <h2 class="mx-2">
        Telefone: {{ getdetailsServiceDespachante.result.client.cellPhone }}
      </h2>
    </div>
    <div class="d-flex align-items-center" v-else>
      <h2 class="mr-2">Dados Não informados</h2>
    </div>

    <div class="containerMain">
      <TagVue :tags="getdetailsServiceDespachante.result" ref="tag" />
      <hr />
      <userDataVue :dataTransaction="getdetailsServiceDespachante.result" />
      <div
        v-if="
          (getdetailsServiceDespachante.result.documents &&
            getdetailsServiceDespachante.result.documents.length) ||
            getdetailsServiceDespachante.result.tagStatus == 2
        "
      >
        <hr />
        <documentUser
          :document.sync="getdetailsServiceDespachante.result"
          @itensDocument="documents"
        />
      </div>
      <hr />
      <comment
        :activities="getdetailsServiceDespachante.result"
        @close="closeModal"
      />
    </div>
    <div
      v-if="
        get_tenant.isDispatcher &&
          $route.params.id == 'solicitacao' &&
          getdetailsServiceDespachante.result.tagStatus == 1
      "
      class="d-flex justify-content-end"
    >
      <!-- <button class="btn err" @click="saveRequest(itemAccompany, false)">Rejeitar</button> -->
      <div class="btn-group">
        <button
          type="button "
          class="btn err btn-danger dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Selecione uma opção
        </button>
        <div class="dropdown-menu p-0 w-100">
          <button
            class="dropdown-item itemBtn"
            @click="saveRequest(itemAccompany, 1)"
          >
            Reprovar
          </button>
          <button
            class="dropdown-item itemBtn"
            @click="saveRequest(itemAccompany, 2)"
          >
            Rejeitar
          </button>
        </div>
      </div>
      <button class="btn sucess" @click="saveRequest(itemAccompany, 0)">
        Aprovar
      </button>
    </div>
    <div
      v-if="
        get_tenant.isDispatcher &&
          $route.params.id == 'solicitacao' &&
          getdetailsServiceDespachante.result.tagStatus !== 1
      "
      class="d-flex justify-content-end"
    >
      <button
        v-if="openSaveButton"
        class="btn sucess"
        @click="modifyStateServiceRequest(itemAccompany)"
      >
        Salvar
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../modal/modalDefault.vue";
import TagVue from "./components/tag.vue";
import userDataVue from "./components/userData.vue";
import documentUser from "./components/document.vue";
import comment from "./components/comment.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { TheMask } from "vue-the-mask";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: {
    itemAccompany: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      documentsFiles: [],
      openSaveButton: false,
      inputChange: false,
      valueInput: "",
      loadSendPlate: false,
      hexTokens: {
        G: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() }, // numero com Letras Mai
        "#": { pattern: /\d/ }, // Numero
        X: { pattern: /[0-9a-zA-Z]/ }, // Numeros e letras
        A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
        T: { pattern: /[0-9a-zA-Z-@]/ },
        "!": { escape: true }
      }
    };
  },
  components: {
    Modal,
    TagVue,
    userDataVue,
    documentUser,
    comment,
    TheMask,
    HalfCircleSpinner
  },
  computed: {
    ...mapGetters([
      "getdetailsServiceDespachante",
      "get_tenant",
      "getTagCurrent",
      "getDataItemDetails",
      "getOpenButtonSave"
    ])
  },
  methods: {
    ...mapMutations([
      "setDetailsServiceDespachante",
      "setAccompanyServiceDespachanteController",
      "setTagCurrent",
      "setOpenButtonSave"
    ]),
    ...mapActions([
      "ApproveOrRecuseService",
      "ModifyStateService",
      "getAllController",
      "InsertVehiclePlate",
      "getDetails"
    ]),

    dashItemsController(page) {
      this.setAccompanyServiceDespachanteController({ result: {}, load: true });
      this.getAllController(page)
        .then(({ data }) => {
          const { result } = data;
          this.setAccompanyServiceDespachanteController({
            result: result,
            load: false
          });
        })
        .catch(err => {
          this.setAccompanyServiceDespachanteController({
            result: {},
            load: false
          });
          this.toast(err.response.data.error.message, this.$toast.error);
        });
    },

    savePlate() {
      const { value } = document.querySelector(".inputEdit");
      if (value.length < 8) {
        this.toast("Placa inválida", this.$toast.error);
        return;
      }

      this.loadSendPlate = true;
      const paramsJson = {
        id: this.getDataItemDetails.id,
        tenantId: this.getDataItemDetails.tenantId,
        vehiclePlate: value
      };

      this.InsertVehiclePlate(paramsJson)
        .then(res => {
          this.toast("Placa alterada com Sucesso", this.$toast.success);
          this.loadSendPlate = false;
          this.valueInput = "";
          this.inputChange = false;
          this.closeModal();
        })
        .catch(err => {
          this.toast(err.response.data.error.message, this.$toast.error);
          this.loadSendPlate = false;
        });
    },

    saveRequest(item, Approve) {
      //! aprovado = 0
      //! reprovar = 1
      //! Rejeitar = 2
      const paramsJsonRequest = {
        id: item.id,
        tenantId: item.tenantId,
        documents: this.documentsFiles,
        aprovade: Approve
      };
      //console.log("1", this.documentsFiles);
      //console.log("2", this.getdetailsServiceDespachante.documents); // não aprova se não selecionar nada

      this.ApproveOrRecuseService(paramsJsonRequest)
        .then(res => {
          if (!Approve) {
            this.toast("Serviço aprovado com Sucesso", this.$toast.success);
          } else if (Approve == 1) {
            this.toast("Serviço Reprovado", this.$toast);
            this.closeModal();
          } else {
            this.toast("Serviço Rejeitado", this.$toast);
            this.closeModal();
          }
          this.$emit("filter");
          this.getDetails({
            id: paramsJsonRequest.id,
            tenantId: paramsJsonRequest.tenantId
          })
            .then(({ data }) => {
              this.setDetailsServiceDespachante({
                result: data.result,
                load: false
              });
            })
            .catch(err => {
              console.log(err);
              this.setDetailsServiceDespachante({ result: {}, load: false });
              this.toast(err.response.data.error.message, this.$toast.error);
            });
        })
        .catch(err => {
          console.log(err);
          this.toast(err.response.data.error.message, this.$toast.error);
        });
    },

    documents(item) {
      this.documentsFiles = item;
    },

    modifyStateServiceRequest(item) {
      const paramsJsonRequest = {
        id: item.id,
        tenantId: item.tenantId,
        tag: this.getTagCurrent
      };

      this.ModifyStateService(paramsJsonRequest)
        .then(res => {
          this.toast("Salvo com sucesso", this.$toast.success);
          this.closeModal();
        })
        .catch(err => {
          this.toast(err.response.data.error.message, this.$toast.error);
        });
    },

    closeModal() {
      this.$refs.tag.clearFilter();
      $("#modal_info").modal("hide");
      this.openSaveButton = false;
      //const { id } = this.$route.params;
      this.setOpenButtonSave(false);
      // if (id != "solicitacao") {
      //   setTimeout(() => {
      //     $("#modal_pedido").modal("show");
      //   }, 100);
      // }
    }
  },
  watch: {
    getOpenButtonSave(newValue, oldValue) {
      if (newValue) {
        this.openSaveButton = true;
        this.setOpenButtonSave(false);
      }
    }
  }
};
</script>

<style scoped>
h2 {
  font-weight: 400;
  font-size: 14px;
  color: #5397cc;
}

hr {
  border: 1px solid #a5a6ad4f;
}

.containerMain {
  max-height: 400px;
  overflow: overlay;
  padding-right: 10px;
}

.btn {
  color: #ffffff !important;
  width: 110px !important;
  margin: 20px 0 10px 10px;
}
.err {
  width: 210px !important;
  /* background-color: #F65757; */
}
.err:hover {
  background-color: #f65757be;
}
.sucess {
  background-color: #25d366;
}
.sucess:hover {
  background-color: #25d365b7;
}

.itemBtn {
  height: 40px;
  background: #f1f1f1;
  padding: 0 10px;
  color: red;
}

.itemBtn:hover {
  background-color: #e64949ea;
  color: #fff;
}

.edit {
  border: none;
  padding: 7px 10px;
  border-radius: 6px;
  color: #25d366;
  margin: 0 10px 0 0;
  outline: none;
}

.save {
  background-color: #25d366;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.inputEdit {
  border: none;
  outline: none;
  margin: 10px;
  background: #f0f0f0;
  height: 30px;
  padding: 10px;
  border-radius: 5px;
}
</style>
