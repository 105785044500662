<template>
  <div class="btns">
    <vue-json-to-csv
      :json-data="dataJson"
      :labels="{
        orderId: { title: 'PEDIDO'},
        tenantDispatcher: { title: 'UNIDADE DESPACHANTE'},
        vehiclePlate: { title: 'PLACA' },
        serviceName: { title: 'SERVIÇO' },
        status: { title: 'STATUS' },
        tag: { title: 'TAG' },
        dateOperation: { title: 'DATA - HORA'},
        value: { title: 'PREÇO' },
        comissionTenant: { title: 'RECEITA UNIDADE CLIENTE'},
        comissionPayable: { title: 'COMISSÃO A PAGAR' },
      }"
      :separator="';'"
      @success="val => handleSuccess(val)"
      @error="val => handleError(val)"
    >
      <button v-show="visible" ref="exports" class="btn_default">Extrair</button>
    </vue-json-to-csv>
  </div>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  props: ["dataJson",'visible'],
  components: {
    VueJsonToCsv
  },
  methods: {
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e, "erro");
    }
  },
  watch: {
    // dataJson (newValue, oldValue) {
    //   if (newValue && newValue != '' && this.visible == false) {
    //     this.$refs.exports.click()
    //   }
    //   this.dataJson = ''
    // }
  },
};
</script>

<style scoped>
.btns button {
  height: 40px;
  width: 115px;
  border-radius: 5px;
  border: 2px solid;
  background-color: #a9a2a2;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
  background-color: #e0a638;
  border: none;
}
.btns button:hover {
  background-color: #f38235;
}
@media screen and (max-width:767px){
  .btns{
    margin-top: 2%;
    width: 100%;
  }
  .btns button{
    width: 100%;
  }
}
</style>
