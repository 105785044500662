<template>
  <div class="card-scene">
    <div class="d-flex">
      <div v-for="(item, index) in filterCars" :key="item.id">
        <div class="card-container" :style="`background:${color(item.id)}`">
          <div :class="`card-column-header p-2 text-white`">
            {{ item.name }}
            <hr />
          </div>
          <div>
            <div>
              <div class="card" v-if="cardItem(idItem)[0].status === item.id">
                <div class="mb-2">
                  <div class="tag"></div>
                </div>
                <div
                  @click="
                    $emit(
                      'openModal',
                      accompanyServiceDespachante.resultService,
                      item
                    )
                  "
                >
                  <div>
                    <span>{{
                      cardItem(idItem)[0].vehiclePlate
                        ? getAccompanyServiceDespachante.result.vehiclePlate
                        : ""
                    }}</span>
                    <hr class="containerhr" />
                    <p>
                      {{ getAccompanyServiceDespachante.result.serviceName }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between footerContainer">
                    <div>
                      <img
                        src="@/assets/image/icones/message.png"
                        alt="message"
                      />
                    </div>
                    <!-- {{cardItem(idItem)[0]}} -->
                    <p>{{ cardItem(idItem)[0].dateOperation }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    cards: {
      type: Array,
      default: []
    },
    idItem: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      accompanyServiceDespachante: []
    };
  },
  computed: {
    ...mapGetters(["getAccompanyServiceDespachante"]),
    filterCars() {
      const filter = this.cards.filter(item => item.id > 0);
      return filter;
    }
  },
  mounted() {
    this.accompanyServiceDespachante = this.getAccompanyServiceDespachante.result;
  },
  methods: {
    returnDataJoin(item1, item2) {
      return [...item1, item2];
    },
    color(id) {
      const colorCard = {
        1: "#3F88C1",
        2: "#E99637",
        3: "#39A935"
      };
      return colorCard[id];
    },
    cardItem(id) {
      const { resultService } = this.getAccompanyServiceDespachante.result;
      const result = resultService.filter(item => id === item.id);
      return result;
    }
  }
};
</script>

<style scoped>
.card {
  width: 238px;
  padding: 12px;
  margin: 0 auto 10px;
  height: 125px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  cursor: pointer;
}

.card-container {
  width: 258px;
  margin: 12px;
  padding: 0 0 10px 0;
  background-color: #39a935;
  border-radius: 6px;
}

hr {
  border-bottom: 1px solid #ffffff;
  margin: 11px 0;
}

/* .pendent {
  background: #3F88C1;
  border-radius: 6px;
}

.current {
  background: #E99637;
  border-radius: 6px;
}

.finish {
  background: #39A935;;
  border-radius: 6px;
} */

.tag {
  background: #ff9f1a;
  border-radius: 5px;
  height: 11px;
  width: 45px;
}

span {
  font-weight: 500;
  font-size: 16px;
  color: #797984;
}

.containerhr {
  background: #e9e9eb;
  margin: 5px 0;
  border: 1px solid #e9e9eb;
}

.card p {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #3f88c1;
}

.footerContainer p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #a5a6ad;
}

.cursor {
  cursor: pointer;
}

.grabbing {
  cursor: grabbing;
}
@media screen and (max-width:767px){
  .d-flex{
    flex-direction: column;
  }
}
</style>
