<template>
  <div>
    <div
      class="containerHeader d-flex align-items-center justify-content-between"
    >
      <h2>Atividade:</h2>
      <div class="btn-group">
        <button
          type="button "
          class="btn visEye dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Selecione uma opção"
        >
          <font-awesome-icon class="mx-2" icon="fas-light fa-eye" />
        </button>
        <div class="dropdown-menu p-0 w-100">
          <button class="dropdown-item itemBtn" @click="eyeSendNotification">
            Marcar Lido
          </button>
          <button
            class="dropdown-item"
            :class="filter === 'showAll' ? 'itemBtnActive' : 'itemBtn'"
            @click="eyeSendNotificationType('showAll')"
          >
            Visualizar Todas
          </button>
          <button
            class="dropdown-item"
            :class="filter === 'hideAll' ? 'itemBtnActive' : 'itemBtn'"
            @click="eyeSendNotificationType('hideAll')"
          >
            Ocultar Todas
          </button>
          <button
            class="dropdown-item"
            :class="filter === 'hideAuto' ? 'itemBtnActive' : 'itemBtn'"
            @click="eyeSendNotificationType('hideAuto')"
          >
            Ocultar Auto
          </button>
        </div>
      </div>
    </div>
    <div class="containerFiles">
      <div class="inputFiles d-flex">
        <textarea
          class="text"
          rows="1"
          placeholder="Escrever um comentário"
          @focus="showIntens = true"
          v-model="message"
        ></textarea>
        <button class="btnSendForm file" @click="openFiles" v-show="showIntens">
          <img src="@/assets/image/iconesUpload/attach.png" alt="" />
        </button>
        <button
          class="btnSendForm"
          @click="sendFilesMessage"
          v-show="showIntens"
        >
          Enviar
        </button>
      </div>
      <input
        id="inputFilesID"
        @change="files($event)"
        type="file"
        name="file"
        multiple
        ref="files"
        class="none"
        accept="application/pdf"
      />
      <div class="containerPDF" v-for="(item, index) in file" :key="index">
        <img src="@/assets/image/iconesUpload/2.svg" alt="pdf" />
        <span class="mx-3">{{ item.name }}</span>
        <button class="closePDF" @click="closeItem(item)">x</button>
      </div>
    </div>
    <div class="mt-3 containerMessage" v-for="item in resultQuery">
      <div class="d-flex">
        <h3
          :class="
            $store.getters.tenantRequire.tenantId == item.tenantId
              ? 'titleText mr-4 name'
              : 'titleText mr-4 name coloName'
          "
        >
          {{ item.userName }}
        </h3>
        <h3 class="titleText">
          {{ item.dateMessage.split(" ")[0] }}
        </h3>
        <h3 class="titleText ml-3">
          {{ item.dateMessage.split(" ")[1] }}
        </h3>
      </div>
      <div class="textComment" v-if="!item.link">
        <div v-html="item.message"></div>
      </div>
      <div
        v-else
        v-for="itemFile in JSON.parse(item.message)"
        class="containerPDFDowload"
        @click="dowloadFile(itemFile, item.jobId)"
      >
        <div v-if="item.jobId != 'error'" class="d-flex align-items-center">
          <img src="@/assets/image/iconesUpload/2.svg" alt="pdf" />
          <p>{{ itemFile.substring(itemFile.indexOf("_") + 1) }}</p>
        </div>
        <div v-if="item.jobId && item.jobId != 'error'">
          <half-circle-spinner
            :animation-duration="1000"
            :size="40"
            color="red"
            class="mx-2"
          />
        </div>
      </div>
      <div v-if="!item.jobId">
        <!-- <button
          class="btn err"
          v-if="
            !item.msgNotDelete &&
              $store.getters.tenantRequire.tenantId == item.tenantId &&
              $store.getters.getUser.id == item.userId
          "
          @click="deleteMessageCurrent(item.id, item.serviceDispatcherId)"
        >
          Deletar
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { HalfCircleSpinner } from "epic-spinners";
export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    activities: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      message: "",
      preload: [],
      file: "",
      showIntens: true,
      filter: "hideAuto"
    };
  },
  computed: {
    ...mapGetters([
      "tenantRequire",
      "getItemCardService",
      "getTenantIdCurrentDelete",
      "get_message",
      "getdetailsServiceDespachante"
    ]),
    resultQuery() {
      if (this.getdetailsServiceDespachante.result.activities) {
        if (this.filter === "showAll") {
          return this.getdetailsServiceDespachante.result.activities;
        }

        if (this.filter === "hideAll") {
          return [];
        }

        if (this.filter === "hideAuto") {
          return this.getdetailsServiceDespachante.result.activities.filter(
            s => !s.msgNotDelete
          );
        }
      }
      return [];
    }
  },
  methods: {
    ...mapMutations(["setDetailsServiceDespachante"]),
    ...mapActions([
      "sendMessageDispatcher",
      "getNewMessages",
      "deleteMessage",
      "sendMessageFile",
      "getFiles",
      "senVisualized"
    ]),

    eyeSendNotification() {
      const { id } = this.getItemCardService;
      const item = {
        id,
        tenantId: this.getTenantIdCurrentDelete
      };
      this.senVisualized(item)
        .then(res => {
          this.toast(
            `Pedido ${this.getdetailsServiceDespachante.result.orderId} marcado como Lido!`,
            this.$toast.info
          );
          this.$emit("close");
        })
        .catch(err => {
          this.toast(err.response.data.error.message, this.$toast.error);
        });
    },

    eyeSendNotificationType(type) {
      console.log(type);
      this.filter = type;
    },

    openFiles() {
      this.$refs.files.click();
    },

    changeShow() {
      this.showIntens = true;
    },

    async dowloadFile(item, jobId) {
      const { id } = this.getItemCardService;
      const items = {
        item,
        id: id,
        tenantId: this.getTenantIdCurrentDelete
      };

      if (jobId && jobId != "error")
        return this.toast("Item indisponivel para Download", this.$toast.error);
      this.toast("Iniciando Download", this.$toast);

      try {
        const { data } = await this.getFiles(items);
        const { image64, nameFile, mimeType } = data.result;

        let a = document.createElement("a");
        a.href = `data:${mimeType};base64,${image64}`;
        a.download = nameFile;
        a.click();
      } catch (error) {
        console.log(error);
      }
    },

    closeItem(item) {
      const newValue = [];

      this.file.forEach(items => {
        if (item != items) {
          newValue.push(items);
        }
      });
      this.file = newValue;
    },

    files(event) {
      this.file = event.target.files;
    },

    sendFilesMessage() {
      const { message, file } = this;
      if (!message && !file)
        return this.toast(
          "Pelo menos um dos campos deve ser Preenchidos",
          this.$toast
        );

      if (message && !file) {
        this.saveMessage();
        return;
      }

      if (!message && file) {
        this.saveFiles();
        return;
      }
      this.saveMessage();
      this.saveFiles();
    },

    async saveFiles() {
      const { id } = this.getItemCardService;
      const tenantId = this.getTenantIdCurrentDelete;
      const { file } = this;

      let data = new FormData();

      for (let i = 0; i < file.length; i++) {
        data.append(file[i].name, file[i]);
      }

      const item = {
        id,
        tenantId,
        inputFile: data
      };

      try {
        //let queryLegth = this.dataMessage.length;
        await this.sendMessageFile(item);
        this.file = "";
        document.getElementById("inputFilesID").value = "";
        this.toast("PDF enviado", this.$toast);
        // if (queryLegth == this.dataMessage.length) {
        const { id } = this.getItemCardService;
        this.updateMessage(id);
        // }
      } catch (error) {
        this.toast(error, this.$toast.error);
      }
    },

    async saveMessage() {
      if (!this.message) return;
      const { id } = this.getItemCardService;
      const tenantId = this.getTenantIdCurrentDelete;
      // const { tenantId } = this.tenantRequire
      const jsonParams = {
        serviceDispatcherId: id,
        message: this.message,
        tenantId: tenantId
      };

      try {
        //let queryLegth = this.dataMessage.length;
        await this.sendMessageDispatcher(jsonParams);
        this.message = "";
        this.toast("Mensagem salva", this.$toast);
        // if (queryLegth == this.dataMessage.length) {
        //   this.updateMessage(id);
        // }
      } catch (error) {
        this.toast(error.msg, this.$toast.error);
      }
    },

    async updateMessage(id) {
      const item = {
        id,
        tenantId: this.getTenantIdCurrentDelete
      };
      try {
        const { data } = await this.getNewMessages(item);
        const { result } = data;
        this.getdetailsServiceDespachante.result.activities = result;
        this.setDetailsServiceDespachante(this.getdetailsServiceDespachante);
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMessageCurrent(idMessage, id) {
      try {
        await this.deleteMessage(idMessage);
        this.toast("Mensagem Deletada com Sucesso", this.$toast);
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    get_message: function() {
      if (
        this.get_message == `10_${this.$store.getters.tenantRequire.tenantId}`
      ) {
        const { id } = this.getItemCardService;
        if (id) {
          this.updateMessage(id);
        }
      }
    }
  }
};
</script>

<style scoped>
.containerHeader h2 {
  font-weight: 500;
  font-size: 14px;
  color: #4c4d5b;
}

.text {
  width: 100%;
  padding: 10px 20px 0;
  border: none;
  border-radius: 6px;
  color: #9898a7;
  outline: none;
  background: transparent;
}

.text:focus {
  background-color: #2221210d;
}

.titleText {
  font-weight: 400;
  font-size: 14px;
  color: #5397cc;
}

.name {
  font-weight: 700;
}
.textComment {
  width: 100%;
  padding: 8px 10px 10px;
  border: 1px solid #e9e9eb80;
  background: #c7c7d480;
  border-radius: 6px;
  outline: none;
  min-height: 40px;
  font-weight: 300;
}

.btn {
  color: #ffffff !important;
  margin: 5px 0 3px 0px;
  width: 70px !important;
  font-size: 13px;
}
.err {
  background-color: #f65757;
}
.err:hover {
  background-color: #f65757be;
}

.containerMessage {
  border: 1px solid #e3e3e9;
  padding: 10px;
  border-radius: 5px;
}

.containerFiles {
  padding: 20px 10px;
  border-radius: 8px;
}

.uploadFile {
  border: none;
  padding: 10px;
  background: #f38235;
  color: #fff;
  border-radius: 5px;

  transition: ease-in-out 0.2s;
}

.uploadFile:hover {
  background: #d16015;
  color: #fff;
}

.btnSendForm {
  border: none;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  outline: none;
  /* margin: 20px 0; */
  max-height: 40px;
  transition: ease-in-out 0.2s;
  background-color: #257bd3;
}

.file {
  background-color: #2221210d !important;
  margin: 0 10px;
}

.btnSendForm img {
  width: 20px;
}

.cancel {
  background-color: #f65757;
}

.none {
  display: none;
}

.preload {
  width: 30%;
}

.inputFiles {
  background: none;
  border: 2px solid #98c4e6;
  border-radius: 7px;
  padding: 10px;
}

.containerPDF {
  background: #f4f4f4;
  /* width: 200px; */
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 7px;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.containerPDF span {
  color: #257bd3;
  font-size: 14px;
  font-weight: 500;
}

.closePDF {
  border: none;
  background: #e20613;
  color: #fff;
  border-radius: 7px;
  padding: 4px 8px;
  cursor: pointer;
}

.containerPDFDowload {
  /* min-width: 50%;
  max-width: 50%; */
  background: #f4f4f4;
  padding: 15px 9px;
  border-radius: 7px;
  display: flew;
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.containerPDFDowload p {
  color: #257bd3;
  font-size: 15px;
  margin: 0 0 0 10px;
}

b {
  font-weight: 700;
}

.coloName {
  color: #d16015;
}

.visEye {
  height: 40px;
  border: none;
  border-radius: 5px;
  background: #257bd3bf;
  color: #ffffff;
  transition: 0.1s ease-in-out;
  outline: none;
}

.visEye:hover {
  background: #257bd3;
}

.itemBtn {
  height: 40px;
  background: #f1f1f1;
  padding: 0 10px;
  color: #257bd3;
}

.itemBtnActive {
  height: 40px;
  background-color: #257bd3bf;
  color: #fff;
  padding: 0 10px;
}

.itemBtn:hover {
  background-color: #257bd3bf;
  color: #fff;
}
</style>
